import React, { useState, useRef } from "react";
import GoogleSvg from "/public/images/icons/google.svg";
import FacebookSvg from "/public/images/icons/facebook.svg";
import XSvg from "/public/images/logos/x/xhorizontal.svg";
import Button from "@/components/button";
import FloatingTextInput from "@/components/floating-text-input";
import Link from "next/link";
import css from "./index.module.scss";
import x from "/scripts/apis/x/index.js";
import config from "/config.json";
import { useRouter } from "next/router";
import {getCookie, setCookie} from "/scripts/utils/index.js";
import { useUser } from "scripts/apis/x/useUser";
import gtmTag from "scripts/tag/gtmTag";


export default function RegisterForm({showLinks = true, showLogo = true, subheadline = "Create a free account to view the infinite art and unlock hidden challenges."}) {
  const [cta, setCta] = useState("Create Free Account");
  const [message, setMessage] = useState("");
  const router = useRouter();
  const user = useUser();

  if (router.query.ref){
    setCookie("signinRef", router.query.ref);
  }

  const [form, setForm] = useState({
    email: useRef(),
    password: useRef(),
  });

  async function handleSubmit() {
    let allValid = true;
    for (const [key, element] of Object.entries(form)) {
      if (!element.current.isValid()) {
        allValid = false;
      }
    }

    if (allValid) {
      setCta("Creating");
      x.query(x.endpoints.AUTH_REGISTER, {
        email: form.email.current.getValue().trim(),
        password: form.password.current.getValue().trim(),
      }).then((res) => {
        if (res.error) {
          if (res.error.status === 400) {
            setMessage("Email is taken");
            setCta("Create Free Account");
          } else {
            setStatus("error");
          }
        } else {
          let ref = getCookie("signinRef") || "/";
          gtmTag("registered");
          user.mutate(res);
          setCookie("signinRef", "");
          router.push(ref);
        }
      });
    }
  };

  return (
    <div className={css.registerWrapper}>
      <div className={css.background}><img src="/images/misc/registerFormBg.jpg" alt="" /></div>
      {showLogo && <div className={css.logo}>
        <XSvg />
      </div>}
      <p><b>{subheadline}</b></p>
      <FloatingTextInput
        className={css.input}
        name="email"
        label="Email"
        autocomplete="email"
        ref={form.email}
        validators={[
          { test: (val) => /^$/.test(val), class: "invalid", message: "Required" },
          { test: (val) => /^.*@.*$/.test(val), class: "valid", message: "" },
          { test: (val) => /.*/.test(val), class: "invalid", message: "Must be an email" },
        ]}

      />

      <FloatingTextInput
        name="password"
        className={css.input}
        label="Password"
        type="password"
        ref={form.password}

        validators={[
          { test: (val) => /^$/.test(val), class: "invalid", message: "Required" },
          { test: (val) => /^.{0,7}$/.test(val), class: "invalid", message: "Must be 8+ characters" },
          { test: (val) => /[^A-z0-9]/.test(val), class: "invalid", message: "Numbers and letters only" },
          { test: (val) => /^(^([^0-9])*)$/.test(val), class: "invalid", message: "Must have a number" },
          { test: (val) => /^(^([^A-Z])*)$/.test(val), class: "invalid", message: "Must have an uppercase letter" },
          { test: (val) => /^(^([^a-z])*)$/.test(val), class: "invalid", message: "ust have a lowercase letter" },
          { test: (val) => /^..*$/.test(val), class: "valid", message: "Strength: high" },
        ]}
      />
      <Button raised className={css.button} label={cta} onClick={handleSubmit} />
      <div className={css.message}>{message}</div>
      <div className={css.separator}><div>or</div></div>

      <div className={css.providers}>
        <Button className={css.providerButton} label="Register with Google" ghost grayscale href={`${config.contentUrl}/api/connect/google`} icon={<GoogleSvg />} />
        <Button className={css.providerButton} label="Register with Facebook" ghost grayscale href={`${config.contentUrl}/api/connect/facebook`} icon={<FacebookSvg />} />
      </div>

      {showLinks && <div className={css.links}>
        <Link href="/auth/signin">Sign In</Link>
      </div>}
    </div>
  );
}
