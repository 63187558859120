import React, { useState, useRef, forwardRef, useImperativeHandle } from "react";

import css from "./index.module.scss";

export default forwardRef(({
  name = "",
  validators = [],
  onfocus = () => { },
  onBlur = () => { },
  onChange = () => { },
  onFinish = () => { },
  type = "text",
  autocomplete = "off",
  label = "",
  multiline = false,
  light = false,
  initialValue = "",
  className = "",
  disabled = false,
  suggestions = [],
}, ref) => {
  const [message, setMessage] = useState("");
  const [value, setValue] = useState(initialValue);
  const [validity, setValidity] = useState(null);
  const [finished, setFinished] = useState(null);
  const [suggestionsState, setSuggestionsState] = useState(suggestions.map((suggestion) => ({ visible: true, bold: "", regular: suggestion, value: suggestion })));
  const inputHolder = useRef();
  const input = useRef();

  //generate component className
  let fullClassName = `inputComponent ${css.floatingTextInput} ${className}`;
  if (light) {
    fullClassName += ` light ${css.light}`;
  }

  if (validity === false) {
    fullClassName += ` ${css.invalid}`;
  } else if (validity === true) {
    fullClassName += ` ${css.valid}`;
  }

  if (multiline) {
    fullClassName += ` ${css.multiline}`;
  }
  if (disabled) {
    fullClassName += ` ${css.disabled}`;
  }

  async function validate(newValue){
    for (let validator of validators){
      if (await validator.test(newValue)){
        let result = false;
        if (validator.class === "valid"){
          result = true;
        } else if (validator.class === "invalid") {
          result = false;
        }

        setMessage(validator.message);
        setValidity(result);
        return result;
      }
    }
  }


  useImperativeHandle(ref, () => ({
    isValid() {
      return validate(value);
    },
    getValue() {
      return value;
    },
    setValid(newValidity) {
      setValidity(newValidity);
    },
    setMessage(newMessage) {
      setMessage(newMessage);
    },
  }));

  function clickSuggestion(suggestion) {
    setValue(suggestion.value);
  }



  return (
    <div className={fullClassName}>
      <div className={css.inputHolder} ref={inputHolder}>
        {multiline && (
          <textarea
            type={type}
            ref={input}
            placeholder="x"
            className={css.input}
            name={name}
            value={value}
            onChange={(event) => {
              setValue(event.target.value);

              //delayed change
              clearTimeout(finished);
              setFinished(setTimeout(() => {
                let isValid = validate(event.target.value);
                onFinish(event.target.value, isValid);
              }, 300));
            }}
            onFocus={onfocus}
            onBlur={(event) => {
              let isValid = validate(event.target.value);
              onBlur(event.target.value, isValid);
            }}
            autoComplete={autocomplete}
            onInput={() => {
              //resize the textarea
              input.current.style.height = "0px";
              input.current.style.height = `${input.current.scrollHeight}px`;
            }}
            onKeyUp={(event) => {
              if (event.keyCode === 13) { //enter
                clearTimeout(finished);
                onFinish(event.target.value);
              }
            }}
          ></textarea>
        )}
        {!multiline && (
          <input
            ref={input}
            type={type}
            placeholder="x"
            className={css.input}
            name={name}
            value={value}
            disabled={disabled}
            onChange={(event) => {
              setValue(event.target.value);
              onChange(event.target.value);

              //delayed change
              clearTimeout(finished);
              setFinished(setTimeout(() => {
                let isValid = validate(event.target.value);
                onFinish(event.target.value, isValid);
              }, 300));
            }}
            onFocus={onfocus}
            onBlur={(event) => {
              let isValid = validate(event.target.value);

              onBlur(event.target.value, isValid);
            }}
            autoComplete={autocomplete}
            onKeyUp={(event) => {
              if (event.keyCode === 13) { //enter
                clearTimeout(finished);
                onFinish(event.target.value);

                //if suggestions
                let newValue = suggestionsState.reduce((prev, cur) => {
                  if (prev) {
                    return prev;
                  }
                  if (cur.visible) {
                    return cur.value;
                  }

                  return prev;
                }, null);

                if (newValue) {
                  event.target.value = newValue;
                  setValue(newValue);
                }

                event.target.blur();
              }

              let typed = event.target.value;
              setSuggestionsState(suggestionsState.map((suggestion) => {
                if (suggestion.value.startsWith(typed)) {
                  return {
                    bold: suggestion.value.slice(0, typed.length),
                    regular: suggestion.value.slice(typed.length),
                    value: suggestion.value,
                    visible: true,
                  };
                }
                return {
                  ...suggestion,
                  visible: false,
                };
              }));
            }}
          />
        )}
        <label htmlFor={name}>
          <span>{label}</span>
        </label>
        <div className={css.suggestions}>
          <div className={css.suggestionsInner}>
            {suggestionsState.filter((suggestion) => suggestion.visible)
              .map((suggestion) => <div key={suggestion.value} className={css.suggestion} onMouseDown={() => {
                clickSuggestion(suggestion);
              }}>
                <b>{suggestion.bold}</b><span>{suggestion.regular}</span>
              </div>)}
          </div>
        </div>
      </div>


      <div className={css.message}>{message}</div>
    </div>
  );
});
